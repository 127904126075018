<template>
  <div id="result-scores">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <section>
        <b-field label="School Classes">
          <b-select
            placeholder="Select a class"
            v-model="schoolClassId"
            expanded
          >
            <option
              v-for="schoolClass in schoolClasses"
              :key="schoolClass.id"
              :value="schoolClass.id"
            >
              {{ schoolClass.name }}
            </option>
          </b-select>
        </b-field>
      </section>
      <section style="padding-top: 24px">
        <b-table :data="resultScores" hoverable>
          <b-table-column field="#" label="#" width="40" numeric v-slot="props">
            {{ resultScores.indexOf(props.row) + 1 }}
          </b-table-column>

          <b-table-column label="Avatar" width="80" v-slot="props">
            <figure class="image is-48x48">
              <img
                class="is-rounded"
                :src="props.row.imageUrl"
                alt="avatar"
                v-if="props.row.imageUrl"
              />
              <img
                class="is-rounded"
                src="@/assets/images/default_avatar.png"
                alt="avatar"
                v-else
              />
            </figure>
          </b-table-column>

          <b-table-column label="Name" width="400" v-slot="props">{{
            props.row.name
          }}</b-table-column>

          <b-table-column label="Total" width="100" v-slot="props">{{
            props.row.ateMarksAverageScore.total
          }}</b-table-column>

          <b-table-column label="Average" width="100" v-slot="props">
            {{ props.row.ateMarksAverageScore.average }}
          </b-table-column>

          <b-table-column label="Comment" width="100" v-slot="props">
            <figure class="image is-24x24">
              <img
                src="@/assets/images/square-green.png"
                alt="avatar"
                v-if="props.row.resultComments"
              />
              <img src="@/assets/images/square-red.png" alt="avatar" v-else />
            </figure>
          </b-table-column>

          <b-table-column label="Psychomotor" width="100" v-slot="props">
            <figure class="image is-24x24">
              <img
                src="@/assets/images/square-green.png"
                alt="avatar"
                v-if="props.row.resultPsychomotors"
              />
              <img src="@/assets/images/square-red.png" alt="avatar" v-else />
            </figure>
          </b-table-column>

          <b-table-column label="Attendance" width="100" v-slot="props">
            {{
              props.row.termAttendance.total
                ? props.row.termAttendance.total
                : 0
            }}
          </b-table-column>

          <b-table-column label="Actions" width="100" v-slot="props">
            <p class="buttons">
              <a
                class="is-small has-text-info"
                @click="initializeInfo(props.row)"
                style="font-size: 1.1em"
              >
                <fa-icon icon="info-circle" size="lg" />
              </a>
            </p>
          </b-table-column>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p>
                  <fa-icon icon="info-circle" class="fa-5x" />
                </p>
                <p>Select a school class to fetch students list</p>
              </div>
            </section>
          </template>
        </b-table>
      </section>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchUser } from '@/assets/js/app_info.js'
import SCHOOL_CLASSES from '../../../graphql/school_class/SchoolClasses.gql'
import ASSIGNED_SCHOOL_CLASSES from '../../../graphql/school_class/AssignedSchoolClasses.gql'

export default {
  name: 'ResultScores',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Result Scores',
      user: {},
      schoolClassId: null,
      schoolClasses: [],
      resultScores: [],
    }
  },
  watch: {
    schoolClassId(value, oldValue) {
      this.setParams()

      if (oldValue) {
        this.$router.replace(`/school/${this.schoolId}/result_class/${value}`)
      }

      if (value !== '0') {
        this.$apollo.addSmartQuery('resultScores', {
          query: gql`
            query resultScores($schoolClassId: Int!) {
              resultScores(schoolClassId: $schoolClassId) {
                id
                name
                termId
                ateMarksAverageScore
                resultComments {
                  id
                }
                resultPsychomotors {
                  id
                }
                termAttendance {
                  id
                  total
                }
              }
            }
          `,
          variables: {
            schoolClassId: parseInt(value),
          },
        })
      }
    },
  },
  methods: {
    initializeInfo(student) {
      this.$router.push(
        `/school/${this.schoolId}/school_class/${this.schoolClassId}/result_info/${student.id}/term_id/${student.termId}`
      )
    },
    fetchSchoolClasses() {
      if (this.user.role === 'host' || this.user.role === 'admin') {
        this.$apollo
          .query({
            query: SCHOOL_CLASSES,
            variables: {
              schoolId: this.schoolId,
            },
          })
          .then((response) => {
            this.schoolClasses = response.data.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      } else if (this.user.role === 'educator') {
        this.$apollo
          .query({
            query: ASSIGNED_SCHOOL_CLASSES,
            variables: {
              id: this.user.id,
            },
          })
          .then((response) => {
            this.schoolClasses =
              response.data.assignedSchoolClasses.schoolClasses
            this.schoolClass = this.schoolClasses.filter(
              (schoolClass) => schoolClass.id == this.schoolClassId
            )[0].name
          })
          .catch((error) => {
            // console.error(error);
          })
      }
    },
    setParams() {
      fetchUser().then((user) => {
        this.user = user
        this.schoolId = user.school_id

        this.fetchSchoolClasses()

        if (user.role == 'educator') {
          this.$store.commit('setSubMenus', [
            {
              name: 'New Ate',
              route: `/school/${user.school_id}/new_ate`,
            },
            {
              name: 'Ate Unmarked',
              route: `/school/${user.school_id}/ate_unmarked`,
            },
          ])
        } else {
          this.$store.commit('setSubMenus', [])
        }
      })
    },
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = this.$route.params.school_id
    this.schoolClassId = this.$route.params.school_class_id
  },
}
</script>

<style scoped></style>
